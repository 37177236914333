import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import LayoutMain from "../components/layout-main/layout-main"
import SeoHeaders from "../components/seo-headers/seo-headers"
import HeroUnit from "../components/hero-unit/hero-unit"
import PostListing from "../components/post-listing/post-listing"
import SidebarMain from "../components/sidebar-main/sidebar-main"

import { useArticles } from "../hooks/use-articles"

const getImage = graphql`
  query {
    serviceImage: file(relativePath: { eq: "branding.png" }) {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Articles = () => {
  const { serviceImage } = useStaticQuery(getImage)
  const posts = useArticles()

  return (
    <LayoutMain className="page-articles">
      <SeoHeaders title="Articles" keywords={[`blog, articles`]} />
      <HeroUnit heroImage={serviceImage} pageTitle="Wildfire Blog">
        <p>We are a full service digital agency.</p>
      </HeroUnit>
      <section>
        <div className="content-section">
          <div className="content-section-inner">
            <div className="content-holder">
              <ul className="post-feed blog-posts">
                {posts.map(
                  ({
                    node: {
                      id,
                      childMdx: { frontmatter, excerpt },
                    },
                  }) => {
                    return (
                      <li key={id}>
                        <PostListing
                          frontmatter={frontmatter}
                          excerpt={excerpt}
                        />
                      </li>
                    )
                  }
                )}
              </ul>
            </div>
            <div className="content-sidebar">
              <SidebarMain />
            </div>
          </div>
        </div>
      </section>
    </LayoutMain>
  )
}

export default Articles
